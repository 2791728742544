import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useCallback, useContext, useEffect } from 'react';
import { EntitySettingsContext } from '@property-folders/web/src/pages/settings/EntitySettingsContext';

export enum AllotmentPaneMode {
  Both = 0,
  Content = 1,
  Preview = 2
}

export function useContentPreviewAllotmentState() {
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const { setAllotmentPaneMode, allotmentPaneMode } = useContext(EntitySettingsContext);

  const onVisibleChange = useCallback((changedIndex: number, changedNowVisible: boolean) => {
    if (!changedNowVisible) {
      return setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Preview : AllotmentPaneMode.Content);
    }

    if (splitEnabled) {
      return setAllotmentPaneMode(AllotmentPaneMode.Both);
    }

    setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Content : AllotmentPaneMode.Preview);
  }, [splitEnabled]);

  useEffect(() => {
    if (splitEnabled) {
      setAllotmentPaneMode(AllotmentPaneMode.Both);
    } else {
      setAllotmentPaneMode(AllotmentPaneMode.Content);
    }
  }, [splitEnabled]);

  return {
    allotmentPaneMode,
    showContentPane: [AllotmentPaneMode.Both, AllotmentPaneMode.Content].includes(allotmentPaneMode),
    showPreviewPane: [AllotmentPaneMode.Both, AllotmentPaneMode.Preview].includes(allotmentPaneMode),
    showBothPanes: allotmentPaneMode === AllotmentPaneMode.Both,
    onVisibleChange,
    splitEnabled,
    setAllotmentPaneMode
  };
}
