import './SideNavMenu.scss';
import { Accordion, Button, Nav } from 'react-bootstrap';
import { Icon } from '../Icon';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useDispatch, useSelector } from 'react-redux';
import { activeFormSectionClick, NavigationFormState } from '@property-folders/common/redux-reducers/navigation';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link, Location, useLocation } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { SideNavPullOut } from '@property-folders/components/dragged-components/SideNavMenu/SideNavPullOut';
import InstallAppButton from './InstallAppButton';
import { TopNavUser } from '../TopNavMenu';
import Navbar from 'react-bootstrap/Navbar';
import { useIsGreatforms } from '../../hooks/useIsGreatforms';
import { OFFLINE_TEXT_SHORT } from '@property-folders/common/data-and-text/constants';

export type SideNavMenuProps = {
  expanded: boolean;
  onExpandToggle: () => void,
  onLogoutClick: () => void
};

interface Products {
  productName: string;
  productId: number;
  icon: string;
  modules: {
    moduleId: number;
    moduleName: string;
    header: boolean;
    parentModuleId?: number;
  }[];
}

export const SideNavMenu = forwardRef<HTMLElement>(function (props: SideNavMenuProps, ref) {
  const dispatch = useDispatch();
  const { expanded: expand, onExpandToggle } = props;
  const { currentSelection, sidebarItems } = useSelector(state=>((state as any)?.navigation?.sidebar?.form as NavigationFormState|undefined) || {} as Record<any,undefined> );
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const [forms, setForms] = useState([] as Products[]);
  const [showForm1, setShowForm1] = useState(false);
  const [pullOut, setPullOut] = useState(null as React.ReactElement | null);
  const location = useLocation();
  const isOnline = useOnline();
  const isGreatforms = useIsGreatforms();
  const logo = isGreatforms
    ? LinkBuilder.propertiesImages('greatforms_logo_strap.svg')
    : LinkBuilder.propertiesImages('reaforms_logo_strap.svg');

  useEffect(() => {
    if (!sessionInfo || !sessionInfo.formSubscriptions) {
      setForms([]);
      return;
    }

    const hasChildren = (id: number) => !!sessionInfo.formSubscriptions.find(fs => fs.parentModuleId === id);

    const products: {[productId: string]: Products} = {};
    for (const fs of sessionInfo.formSubscriptions) {
      const icon = Number(fs.productId) <= 10 ? LinkBuilder.signingImages(`icons/${fs.stateName.toLowerCase()}.svg`) : 'legal';
      const moduleId = Number(fs.moduleId);

      // template modules shouldn't be included in the sidebar, only accessible from templates
      if (fs.templateModule) {
        continue;
      }

      if (products[fs.productId]) {
        products[fs.productId].modules.push({
          moduleId,
          moduleName: fs.moduleName,
          header: hasChildren(moduleId),
          parentModuleId: Number(fs.parentModuleId)
        });
      } else {
        products[fs.productId] = {
          modules: [{
            moduleId,
            moduleName: fs.moduleName,
            header: hasChildren(moduleId),
            parentModuleId: Number(fs.parentModuleId)
          }],
          productName: fs.productName,
          icon,
          productId: fs.productId
        };
      }
    }

    const productsArray: Products[] = [];
    for (const key of Object.keys(products)) {
      for (const module of products[key].modules) {
        if (!module.parentModuleId && !hasChildren(module.moduleId) && !products[key].modules.find(m => m.moduleName === 'Other')) {
          products[key].modules.push({
            moduleId: module.moduleId - 1,
            moduleName: 'Other',
            header: true
          });
        }

        if (hasChildren(module.moduleId)) {
          module.header = true;
        }
      }

      products[key].modules = products[key].modules.filter((item, index) => products[key].modules.findIndex(t => t.moduleId === item.moduleId) === index);
      products[key].modules.sort((a, b) => a.moduleId - b.moduleId);
      productsArray.push(products[key]);
    }

    setShowForm1(sessionInfo.entities.some(e => e.hasSaSubscription));
    setForms(productsArray);
  }, [sessionInfo]);

  function selectedClass(location: Location, expected: string, includeSearchPath = false) {
    const actual = includeSearchPath ? `${location.pathname}${location.search}` : location.pathname;
    return actual === expected ? 'active' : '';
  }

  const propertyFoldersSection = <React.Fragment>
    <Nav.Item className={selectedClass(location, '/properties')}>
      <Link to='/properties' className={'nav-link'}>
        <img src={LinkBuilder.propertiesImages('property-transaction.svg')} className='svg-icon'/><span className='nav-text'>Properties</span>
      </Link>
    </Nav.Item>
    {sidebarItems && sidebarItems.map(navDefn => {
      const iPackSpread = navDefn.iconPack ? { pack: navDefn.iconPack } : { pack: 'material-symbols' };
      const inner = <React.Fragment>
        <Icon name={navDefn.icon ?? ''} variant="outlined" {...iPackSpread}/><span className='nav-text'>{navDefn.label}</span>
      </React.Fragment>;
      const cls = clsJn(currentSelection === navDefn.id && 'active', 'sub-item');
      return <Nav.Item
        className={cls}
        key={navDefn.id}
        id={`nav-form-${navDefn.id}`}
      >
        {navDefn.href
          ? <Nav.Link className={cls} href={navDefn.href}>{inner}</Nav.Link>
          : navDefn.dispatchById
            ? <Nav.Link className={cls} onClick={()=>dispatch(activeFormSectionClick(navDefn.id))}>{inner}</Nav.Link>
            : inner
        }
      </Nav.Item>;
    })}
  </React.Fragment>;

  return (
    <>
      <aside ref={ref} className={clsJn('flex-grow-0 side-main-menu d-flex flex-column bg-white overflow-visible', expand && 'expand')}>
        <Navbar.Brand className="flex-shrink-1 ps-2">
          <Link to={isOnline ? LinkBuilder.homePage : '/properties'}>
            {expand
              ? <img id="logo-large" alt="reaforms" src={logo} className="d-inline-block"></img>
              : <img id="logo-small" alt="reaforms" src={LinkBuilder.propertiesImages('reaforms_icon.svg')} className="d-inline-block"></img>
            }
          </Link>
        </Navbar.Brand>
        <Nav className={clsJn('flex-grow-1 flex-column flex-nowrap overflow-auto', !expand && 'hide-scrollbars')}>
          {propertyFoldersSection}
          {isOnline && <>
            {showForm1 && <Nav.Item className={selectedClass(location, '/form1_dashboard.php')}>
              <Link to='/form1_dashboard.php' className={'nav-link'}>
                <Icon pack='la' name="dashboard"/><span className='nav-text'>Form 1 Dashboard</span>
              </Link>
            </Nav.Item>}
            <Nav.Item className={selectedClass(location, '/formscreated.php')}>
              <Link to='/formscreated.php' className={'nav-link'}>
                <Icon pack='la' name="list-ul"/><span className='nav-text'>Forms Created</span>
              </Link>
            </Nav.Item>
            { forms.map(p => expand
              ? <Accordion key={p.productId} flush={true}>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header className='nav-item'>
                    <div>
                      {p.icon && p.icon.includes('/') && <img className='icon' src={p.icon} />}
                      {p.icon && !p.icon.includes('/') && <Icon name={p.icon} pack='la' />}
                      <span className='nav-text'>{p.productName}</span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Collapse eventKey='0'>
                    <>
                      {p.modules.map((m, idx) => <Nav.Item key={idx} className={clsJn(selectedClass(location, `/formsnew.php?ModuleID=${m.moduleId}`, true), m.header ? 'no-hover' : '')}>
                        {m.header
                          ? <span className='nav-text fw-bold'>{m.moduleName.replaceAll('&amp;', '&')}</span>
                          : <Link to={`/formsnew.php?ModuleID=${m.moduleId}`} className='nav-link'>
                            <span className='nav-text'>{m.moduleName.replaceAll('&amp;', '&')}</span>
                          </Link>
                        }
                      </Nav.Item>)}
                    </>
                  </Accordion.Collapse>
                </Accordion.Item>
              </Accordion>
              : <SideNavPullOut
                key={p.productId}
                text={p.productName}
                items={p.modules.map(m => ({
                  text: m.moduleName,
                  link: m.header ? null : `/formsnew.php?ModuleID=${m.moduleId}`,
                  id: m.moduleId,
                  header: m.header
                }))}
                setFragment={r => { setPullOut(r); }}
                icon={p.icon}
              />
            )}
          </>}
        </Nav>

        {expand && <InstallAppButton />}
        {!isOnline && <div className='d-flex flex-column align-items-center bottom-section-bgonly'><div className='d-flex flex-row align-items-center m-2'><Icon name='signal_disconnected' pack='material-symbols'/>{expand
          ? <span className='ms-2 fs-5'>{OFFLINE_TEXT_SHORT}</span>
          : null
        }</div></div>}
        <div className={clsJn('d-flex bottom-section overflow-visible', expand ? 'flex-row' : 'flex-column')}>
          <TopNavUser onLogoutClick={props.onLogoutClick} expanded={props.expanded}></TopNavUser>
          <Button className='flex-grow-0 flex-shrink-0 expansion-trigger ms-auto' variant='light' onClick={onExpandToggle}><Icon name="keyboard_double_arrow_left"/></Button>
        </div>
      </aside>
      {pullOut}
    </>
  );
});
