import { AuthApi } from '@property-folders/common/client-api/auth';
import { FormsApi } from '@property-folders/common/client-api/formsApi';
import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { useOnline } from './useOnline';
import { ListFormsForAgent } from '@property-folders/contract/rest/forms';

type Row = ListFormsForAgent['results'][0];

export function preloadFormsViaHook() {
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const agentId = session?.agentId ?? 0;
  const [_storageForms, setStorageForms] = useLocalStorage<Row[]>(`userForms_${agentId}`, []);
  const loadOnceRef = useRef(false);
  const isOnline = useOnline();
  useEffect(()=>{
    if (loadOnceRef.current) return;
    if (!isOnline || agentId === 0) return;
    loadOnceRef.current = true;
    FormsApi.listFormsForAgent().then(res => {
      setStorageForms(res.results);
    });
  }, [isOnline, agentId]);
}