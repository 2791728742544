import { Annexure, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { AnnexureEditor } from './AnnexureEditor';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { CollectionEditor } from './CollectionEditor';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { FileSyncContext } from '../../context/fileSyncContext';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Maybe } from '@property-folders/common/types/Utility';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useImmerYjs } from '../../hooks/useImmerYjs';
import { findIndex, orderBy } from 'lodash';
import { processAnnexureHistory } from '@property-folders/common/util/dataExtract';
import { DataGeneration } from '@property-folders/common/util/dataExtractTypes';
import { LineageContext } from '../../hooks/useVariation';
import { Predicate } from '@property-folders/common/predicate';
import { stitch } from '@property-folders/common/util/pdf/pdf-stitch';
import { SingleBlobFileProvider } from '../Wizard/Wizard';
import { preservedAnnexureOrderHistoryList } from '@property-folders/common/util/form/annexure';
import { createAnnexure, deleteAnnexure, updateAnnexureLabelsImmer } from '@property-folders/common/util/annexure';
import { getPathParentAndIndex } from '@property-folders/common/util/pathHandling';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useStore } from 'react-redux';
import * as Y from 'yjs';

export function AnnexuresSection({ formCode, formId, setHeaderActions, ydoc, transactionMetaRootKey = PropertyRootKey.Meta }: {
  formCode: string,
  formId: string,
  setHeaderActions?: SetHeaderActionsFn,
  ydoc?: Y.Doc,
  transactionMetaRootKey: string
}) {
  const store = useStore();
  const outsideDragDropRef = useRef<HTMLDivElement>(null);
  const { snapshotHistory } = useContext(LineageContext);
  const formParentPath = FormUtil.getFormPath(formCode, formId) || '';
  const annexuresPath = `${formParentPath}.annexures`;

  const { updateDraft: updateAnnexures } = useYdocBinder<Annexure[]>({ path: annexuresPath, bindToMetaKey: true });

  const { updateDraft: updateMetaRoot } = useYdocBinder<TransactionMetaData>({ path: '', bindToMetaKey: true });
  const annexuresForm = useLightweightTransaction<Annexure[]>({ parentPath: formParentPath, myPath: 'annexures', bindToMetaKey: true })?.value??[];

  const { annexures, usingPrevious, orderList } = useMemo(()=>{
    return preservedAnnexureOrderHistoryList(annexuresForm, snapshotHistory);
  }, [annexuresForm, snapshotHistory]);

  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { instance: fileSync } = useContext(FileSyncContext);
  const [ errorMessage, setErrorMessage ] = useState<string>('');
  const [ showAddSignedDialog, setShowAddSignedDialog ] = useState<boolean>(false);
  const uploadInput = useRef<HTMLInputElement | null>(null);

  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const signedForms = orderBy(FormUtil.getSignedForms(meta)?.filter(sd => !annexures.map(a=>a.data.linkedFormId)?.includes(sd.id)), d => d.timestamp, 'desc');

  useEffect(()=> {
    updateMetaRoot?.(draft => {
      const { indexer, parent } = getPathParentAndIndex(annexuresPath, draft, true);
      if (parent && !parent[indexer]) parent[indexer] = [];
    });
  }, []);

  useEffect(()=> {
    setHeaderActions?.(() => ({
      'add-annexure': {
        label: 'Add Annexure',
        onClick: handleInsert
      },
      ...(signedForms?.length && {
        'add-signed': {
          label: 'Add signed document',
          onClick: handleAddSigned
        } })
    }));
  }, [annexures]);

  const handleDrop = (acceptedFiles: File[], idx: Maybe<number>) => {
    idx = (idx??0) < 0 ? 0 : idx;
    createAnnexures(acceptedFiles, idx);
  };

  const handleInsert = () => uploadInput?.current?.click();

  const updateAnnexureLabelsDraft = useCallback((draft)=>updateAnnexureLabelsImmer(draft, orderList), [orderList]);

  const updateAnnexureLabels = () => updateAnnexures?.(draft => updateAnnexureLabelsDraft(draft));

  const handleDelete = useCallback(async (deleted: Annexure) => {
    if (!deleted?.id) return;
    await deleteAnnexure(deleted, usingPrevious, annexures, updateAnnexures);
    updateAnnexureLabels();
  }, [usingPrevious, annexures, updateAnnexures]);

  const handleAddSigned = async () => {
    setShowAddSignedDialog(true);
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.target.files?.length && createAnnexures([...event.target.files], undefined);
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files, undefined),
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  const dragAndDropConfig = useMemo(() => ({
    handleClass: 'align-self-center',
    draggableClass: 'mb-2'
  }),[]);

  const createAnnexures = async (files: File[], idx: Maybe<number>) => {
    //only allow 100MB of annexures
    const fileMeta = await Promise.all(annexures.map(a => FileStorage.readMeta(a.data.id)));
    let existingSize = fileMeta?.map(f => f?.size ?? 0)?.reduce((a, f) => a + f, 0);

    for (const file of files) {
      if (existingSize + file.size > 104857600) {
        setErrorMessage('The Annexures you have attached are too large. Only 100MB of Annexures can be attached.');
        return;
      }
      existingSize += file.size;
      createAnnexureCallback(file, idx);
    }
  };

  const createAnnexureCallback = useCallback(async (file: File, idx?: Maybe<number>, linkedFormId?: Maybe<string>, coversheetText?: string) => {
    await createAnnexure({ file,
      idx,
      linkedFormId,
      coversheetText,
      property,
      formId,
      formCode,
      setErrorMessage,
      updateAnnexures,
      updateAnnexureLabelsDraft,
      fileSync,
      store,
      ydoc
    });
  },[annexures, updateAnnexureLabelsDraft]);

  const [selectedDocs, setSelectedDocs] = useState<{name: string, id: string, files: string[]}[]>([]);
  const handleCloseAddSignedDialog = (shouldAttach: boolean) => {
    setShowAddSignedDialog(false);
    if (shouldAttach) {
      selectedDocs.map(async sd => {
        const files = (await Promise.all(sd.files?.map(async f => {
          return await new SingleBlobFileProvider((await FileStorage.read(f))?.data || new Blob([])).getFile();
        })))?.filter((f): f is Uint8Array => !!f);
        const merged = await stitch(files);
        createAnnexureCallback(new File([merged as BlobPart], sd.name, { type: 'application/pdf' }), undefined, sd.id, files.length > 1 ? `Executed as counterpart with ${files.length} parts.` : '');
      });
    }
    setSelectedDocs([]);
  };

  const addSignedDialog =
    <Modal show={showAddSignedDialog}>
      <Modal.Header className={'mb-3'}>
        <h5>Add Signed Documents</h5>
      </Modal.Header>
      <Modal.Body className={'mb-2'}>
        {signedForms.map(f =>
          <Form.Check
            id={f.id.join()}
            label={`${f.name}, signed ${f.timestamp}`}
            key={f.id.join()}
            className={'mb-1'}
            onChange={(e) => {
              setSelectedDocs(p => e.target.checked
                ? [...p, { id: f.id.join(), files: f.id, name: f.name }]
                : p.filter(d => d.id !== f.id.join())
              );
            }}
          >
          </Form.Check>)}
      </Modal.Body>
      <Modal.Footer>
        <div className={'d-flex flex-column'}>
          <div className={'d-flex flex-row gap-3 justify-content-end'}>
            <Button variant={'light'} onClick={() => handleCloseAddSignedDialog(false)}>Cancel</Button>
            <Button variant={'primary'} onClick={() => handleCloseAddSignedDialog(true)}>OK</Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>;

  const readOnlyAnnexures = useMemo(() => {
    const result: { [key: string]: boolean } = {};
    const isReadOnly = (a: Annexure) => !!a.noEditRemove || !!a.binding;

    for (const item of annexures) {
      if (!result[item.id]) {
        result[item.id] = isReadOnly(item.data);
      }
    }
    // track read-only status of historical annexures too - shouldn't be allowed to restore deleted read-only annexures.
    // since a human didn't delete it, a human shan't restore it either.
    for (const item of orderList || []) {
      if (!result[item.id]) {
        result[item.id] = isReadOnly(item.data);
      }
    }

    return result;
  }, [annexures, orderList]);
  const areNoAnnexures = (annexures?.length??0) === 0;
  const childProps = useMemo(()=> ( { onDrop: handleDrop } ),[annexures]);

  const restoreItemHandler = (restoreItem: any) => {
    console.log('restoreItem', structuredClone(restoreItem));
    restoreItem = { ...restoreItem, _restoredMarker: true };
    delete restoreItem._removedMarker;
    updateAnnexures?.(list=> {
      if (!Array.isArray(list) || restoreItem == null) {
        return;
      }
      // items in 'list' should all be deletion or restore markers, and if there's a restore marker,
      // it shouldn't even call this function
      const currentList = list
        .map((a,idx)=>a.id===restoreItem.id?idx:undefined)
        .filter(Predicate.isNotNullish);

      if (currentList.length > 0) {
        currentList.reverse().forEach(rIdx=>list.splice(rIdx,1));
        return;
      }

      if (orderList) {
        const insertionIndex = findIndex(
          orderList
            ?.filter(or => or.id === restoreItem.id || or.state !== DataGeneration.Removed), // We need a new Restored state, and carried over won't be in this list
          or=>or.id === restoreItem.id
        );
        list.splice(insertionIndex, 0, restoreItem);
      } else {
        list.push(restoreItem);
      }

    });
  };

  return <><div {...(areNoAnnexures ? getRootProps() : {})} className={clsJn('scrollspy-target AnnexuresSection', isDragAccept && 'drop-accept', areNoAnnexures && 'show-placeholder' )} data-focus-path="subsection-annexures">
    <input ref={uploadInput} className={'d-none'} type="file" accept={'.pdf'} multiple onChange={handleUpload} />
    <input {...(areNoAnnexures ? getInputProps() : {})} className={'d-none'} />
    {errorMessage && <Alert variant='danger' dismissible onClose={()=>setErrorMessage('')}>{errorMessage}</Alert>}
    {areNoAnnexures && <div>Drag and Drop PDF's here to attach</div>}
    <div className={'w-100 d-flex flex-column'}>
      <CollectionEditor
        allowAdd={false}
        childItemRenderer={AnnexureEditor}
        parentPath={formParentPath}
        onDelete={handleDelete}
        myPath='annexures'
        bindToMetaKey={true}
        allowDragAndDrop={true}
        dragAndDropConfig={dragAndDropConfig}
        childProps={childProps}
        onReorder={updateAnnexureLabelsDraft}
        variationDetectionFunction={processAnnexureHistory}
        dataModelDoesNotRetainPrevious={true}
        restorationFieldDisplay='name'
        itemNoun='Annexure'
        externalRestore={restoreItemHandler}
        deletionListPortalRef={outsideDragDropRef}
        restoreOnlyThisVariationDeleted={true}
        isChildReadonly={(child: { id: string }) => readOnlyAnnexures[child.id]}
      />
    </div>
    {!areNoAnnexures && <div style={{ height: '30px' }} {...getRootProps()} className={clsJn('new-placeholder', isDragAccept && 'drop-accept')} tabIndex={-1}></div>}

  </div>
  <div ref={outsideDragDropRef}></div>
  {addSignedDialog}
  </>;
}

