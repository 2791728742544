import { Button } from 'react-bootstrap';
import './CollectionRemoveButton.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Icon } from '../Icon';

export const CollectionRemoveButton = ({ onRemove, removable=false, className, ...restProps }: {
  onRemove: ()=>void
  removable: boolean
  className?: string
}) => <Button disabled={!removable}
  onClick={()=>{onRemove?.();}}
  variant='light'
  className={clsJn('btn-list-action coll-del-button form-floating', className)}
  title='Remove this item'
  {...restProps}
>
  {/*<i className='bi-trash-fill'></i>*/}
  <Icon pack={'material-symbols'} name={'delete'} variant={'outlined'}></Icon>
</Button>;
