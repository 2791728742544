import { Link } from 'react-router-dom';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export function OfflinePage() {
  const featureFlags = useFeatureFlags();
  if (!featureFlags?.newPropertyFolders) {
    return <div className='d-flex justify-content-center mt-4 fs-4 w-100'><div>Offline mode is only available for Greatforms, please contact our sales team about trying out Greatforms today!</div></div>;
  }
  // We should be preventing the user navigating to these pages in the first place, but if that fails, use this component
  return <div className='page-error d-flex flex-column align-items-center text-center'>
    <h1>Something went wrong.</h1>
    <div className='d-flex mt-4'>
      <h4 className='text-secondary text-center' style={{ maxWidth: 270 }}>This feature is not available while offline.</h4>
    </div>
    <Link to='/properties'>
      <button className='home btn text-white mt-4'>Go to properties</button>
    </Link>
  </div>;
}