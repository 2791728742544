import { useTransactionField } from '../../hooks/useTransactionField';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';
import { CollectionEditor, EditorListChildProps } from './CollectionEditor';
import { NarrowPlanInput } from './NarrowPlan';
import { portionOptionsBoolean, portionOptionsExpanded } from '@property-folders/common/data-and-text/constants';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { Icon } from '../Icon';
import React, { useEffect, useState } from 'react';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { Title } from './Title';
import { CollectionValidCheck } from './CollectionValidCheck';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { SaleTitle, TitleInclusionState } from '@property-folders/contract';
import { useTimeout } from '../../hooks/useTimeout';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useIsSailisOutage } from '@property-folders/web/src/redux/useIsSailisOutage';
import { Button, Modal } from 'react-bootstrap';
import {
  determineTitleInclusionState, getLinkedTitles
} from '@property-folders/common/yjs-schema/property/validation/expected-evaluator';
import { useOnline } from '../../hooks/useOnline';

type TitleInputProps = EditorListChildProps & {
  allTitles?: SaleTitle[]
};

export const wholePortionsOnly = false;

export const NarrowTitleInput = ({ removable = true, editable = true, hideDelete = false, onUpdate, onDelete, duplicate, onUpdateBegin, onUpdateCancel, autoFocus, ...restProps }: TitleInputProps & EditorListChildProps): JSX.Element => {
  const { value: titleItem, fullPath, handleRemove, hasVaried } = useTransactionField<SaleTitle>(restProps);
  const { updateDraft } = useYdocBinder<SaleTitle>({ path: fullPath });
  const [lotsCollapsed, setLotsCollapsed] = useState(true);
  const [currentChange, setCurrentChange] = useState(titleItem);
  const [changeCounter, setChangeCounter] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const isMinWidthLg = useMediaQuery({ minWidth: BP_MINIMA.lg });
  const isSailisOutage = useIsSailisOutage();
  const isOnline = useOnline();

  const confirmDelete = () => {
    try {
      onDelete?.(titleItem);
      handleRemove();
    } finally {
      setShowConfirmDelete(false);
    }
  };
  const confirmExclude = () => {
    try {
      updateDraft?.(draft => {
        for (const st of draft.subTitles ?? []) {
          st.portionType = TitleInclusionState.none;
        }
      });
    } finally {
      setShowConfirmDelete(false);
    }
  };
  const linkedTitles = getLinkedTitles(titleItem, restProps.allTitles);
  const deleteButton = linkedTitles.length
    ? <CollectionRemoveButton removable={removable} onRemove={() => setShowConfirmDelete(true)} />
    : <CollectionRemoveButton removable={removable} onRemove={() => confirmDelete()} />;

  useTimeout(()=>{
    titleItem && !isSailisOutage && isOnline && validateTitle();
  }, 1000, changeCounter === 0 ? null : changeCounter);

  const validateTitle = () => {
    const lookup = PropertySearchApi.getTitleDetails(titleItem.title);
    lookup.response.then(e => {
      if (onUpdate) {
        onUpdate({ title: titleItem, search: e });
      }
    }).catch(()=>{onUpdateCancel?.({ title: titleItem });});
    return ()=>{lookup.abort();};
  };

  useEffect(() => {
    if (wholePortionsOnly && !!updateDraft) {
      updateDraft(draft=>{
        draft.isWhole = true;
      });
      return;
    }
    if (!(titleItem && updateDraft && Array.isArray(titleItem.subTitles) && titleItem.subTitles.length >0)) {
      return;
    }

    // True if all are not explicitly whole
    const originalInclusionState = titleItem.isNone
      ? TitleInclusionState.none
      : titleItem.isWhole
        ?TitleInclusionState.whole
        : TitleInclusionState.portion;
    const { titleInclusionState: newInclusionState } = determineTitleInclusionState([titleItem]);

    if (originalInclusionState !== newInclusionState) {
      updateDraft(draft => {
        draft.isWhole = newInclusionState === TitleInclusionState.whole;
        draft.isNone = newInclusionState === TitleInclusionState.none;
      });
    }
  }, [titleItem, !!updateDraft]);

  const manualTitleValidateButton = <div className="d-flex align-items-center delete-div ms-2 me-3">
    <span style={{ color: 'gray' }} title={'Pending Validation'} className="material-symbols-outlined me-2">preliminary</span>
    {!isSailisOutage && isOnline && <Button variant={'outline-secondary'} onClick={() => validateTitle()}>Validate</Button>}
  </div>;

  if (!editable) {
    return <div className='d-flex w-100 flex-column'>
      <div>
        {portionOptionsBoolean[titleItem.isWhole ? 'true' : 'false']} {titleItem.propertyCacheId
          ? <a target='_blank' href={PropertySearchApi.getPdfFromCache(titleItem.propertyCacheId)}>{titleItem?.title} <Icon style={{ fontSize: 14, verticalAlign: 'top' }} name='launch' pack='material-icons' /></a>
          : titleItem?.title
        }
        {titleItem?.fromLssa && <CollectionValidCheck className='ps-1' inline={true} />}
      </div>
      {titleItem?.subTitles?.length && <div className=''>{titleItem.subTitles.map((st, idx) => (
        <div key={`st_${idx}`} className='d-flex flex-row align-items-center'>
          <div style={{ fontSize: '1.75rem' }} className='me-3'>{idx < (titleItem.subTitles ?? []).length - 1 ? '├' : '└'}</div>
          {portionOptionsExpanded[st.portionType]} {st.lot} {st.lotid} on {st.plan} {st.planid}
        </div>
      ))}</div>}
    </div>;
  }

  const width = wholePortionsOnly || !isMinWidthLg
    ? '100%'
    : titleItem.fromLssa
      ? '320px'
      : '440px';
  const isNoneOf = Boolean(titleItem.subTitles?.every(st => st.portionType === TitleInclusionState.none));

  return (
    <div className="d-flex w-100 gapped-row">
      <div style={{ width }} >
        <div className="d-flex flex-wrap gapped-row" >
          {((Array.isArray(titleItem?.linkedAddresses) && titleItem.linkedAddresses.length > 0))
            ? <>
              <div className="fs-5 cursor-pointer" onClick={()=>setLotsCollapsed(c=>!c)}><Icon pack='bi' name={lotsCollapsed ? 'plus-square' : 'dash-square'} /></div>
              <div className={clsJn('ms-3 mt-1', hasVaried && 'varied-control')}>
                {isNoneOf ? 'Is none of' : portionOptionsBoolean[titleItem.isWhole]} {titleItem.propertyCacheId
                  ? <a target='_blank' href={PropertySearchApi.getPdfFromCache(titleItem.propertyCacheId)}>{titleItem?.title} <Icon style={{ fontSize: 14, verticalAlign: 'top' }} name='launch' pack='material-icons' /></a>
                  : titleItem?.title
                }
                {titleItem?.fromLssa && <CollectionValidCheck className='ps-1' inline={true} />}
              </div>
            </>
            : <>
              <div className='flex-grow-0 d-flex' style={wholePortionsOnly ? {} : { width: '140px' }}>
                {wholePortionsOnly
                  ? <div className='me-2 align-self-center'>{portionOptionsBoolean[titleItem.isWhole]}</div>
                  : <WrField.Select
                    name="isWhole"
                    label="Whole/Portion"
                    options={portionOptionsBoolean}
                    valueType='boolean'
                    parentPath={fullPath}
                    myPath='isWhole'
                    canClear={false}
                  />
                }

              </div>
              <div className='flex-grow-0' style={{ width: '140px' }}>
                <Title
                  parentPath={fullPath}
                  myPath='title'
                  duplicate={duplicate}
                  autoFocus={autoFocus}
                  onChange={e => {
                    const nativeEvt = e.nativeEvent as InputEvent;
                    const isDropInsert = nativeEvt.inputType === 'insertFromDrop';
                    const val = isDropInsert ? nativeEvt.data : e.target?.value;
                    setCurrentChange(val);
                  }}
                  onBlur={() => {
                    if (titleItem !== currentChange) {
                      !isSailisOutage && isOnline && onUpdateBegin?.({ title: titleItem });
                      setChangeCounter(cv=>cv + 1);
                    }
                  }}
                  onFocus={() => {
                    setChangeCounter(0);
                    !isSailisOutage && isOnline && onUpdateCancel?.({ title: titleItem });
                    setCurrentChange(titleItem);
                  }}
                />
              </div>
            </>}
          {titleItem?.fromLssa && <CollectionValidCheck />}
          {!titleItem?.fromLssa && titleItem?.title && manualTitleValidateButton}
          {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
            {deleteButton}
          </div>}
        </div>
        {!lotsCollapsed && <div className="w-100">
          <CollectionEditor
            title=''
            expansionTreeMarks={true}
            compact={true}
            allowAdd={false}
            parentPath={fullPath}
            myPath={'subTitles'}
            childItemRenderer={NarrowPlanInput}
            childProps={{
              allTitles: restProps.allTitles
            }}
          />
        </div>}
      </div>
      {showConfirmDelete && <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
        <Modal.Header>
          <Modal.Title>Title is related to other titles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Removing this title will also remove the other related titles and the address.</p>
          <p>You can exclude this title from the transaction by setting the allotments to "None of".</p>
        </Modal.Body>
        <Modal.Footer className='justify-content-between gap-2'>
          <Button variant='danger' onClick={() => confirmDelete()}>Remove all</Button>
          <div className='d-flex flex-row gap-2'>
            <Button variant='outline-secondary' onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
            <Button onClick={() => confirmExclude()}>Exclude this one</Button>
          </div>
        </Modal.Footer>
      </Modal>}
    </div>
  );
};
