import { useEffect, useState, useContext } from 'react';
import { useLocalStorage } from 'react-use';

import { Button } from 'react-bootstrap';
import { PwaContext } from '../../context/pwa';
import { AuthApi } from '@property-folders/common/client-api/auth';

const InstallAppButton = () => {
  const sessionStateRaw = AuthApi.useLoggingOnSessionInfo();
  const pwa = useContext(PwaContext);
  if (!(pwa && sessionStateRaw.data.session?.type === 'agent' && sessionStateRaw.data.session.featureFlags?.newPropertyFolders)) {
    return (<></>);
  }

  const [isVisible, setVisibleState] = useState(false);
  const [dismissed, setDismissed] = useLocalStorage('dismissedInstallButton', false);

  useEffect(
    () => {
      if (pwa.promptEvent) {
        setVisibleState(true);
      }
    },
    [pwa.promptEvent]
  );

  return (
    <>
      {(!dismissed && isVisible && !window.matchMedia('(display-mode: standalone)').matches) && <div className='flex-grow-0 flex-shrink-0 p-3 bg-primary mb-2'>
        <span className='float-end' style={{ color: 'white', cursor: 'pointer' }} onClick={() => {
          setDismissed(true);
        }}>x</span>
        <p className='text-center' style={{ color: 'white' }}>Access your most recent property folders and sign documents - all while offline.</p>
        <div className='text-center'>
          <Button className='rounded' style={{ borderColor: 'white' }} onClick={pwa.promptToInstall}>Install</Button>
        </div>
      </div>}
    </>
  );
};

export default InstallAppButton;