import { useCallback, useRef, useState } from 'react';
import { Suggestion } from '../form/Suggestor';
import {
  IAddressSearchResponse,
  IPropertySearchSuggestions
} from '@property-folders/contract/rest/address-search';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { Typeahead } from '../Typeahead';
import { composeFullAddressFromParts } from '@property-folders/common/util/formatting/string-composites';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';
import { useOnline } from '../../hooks/useOnline';
import { OFFLINE_TEXT_SHORT } from '@property-folders/common/data-and-text/constants';

export interface BareAddressSelectorProps {
  id: string;
  label: string;
  gnafCentre?: string;
  international?: boolean;
  countries?: ('Australia'|'New Zealand')[]
  autoFocus?: boolean;
  containerClassName?: string;
  onAddressSelect: (value: string, parts?: any, gnaf?: string) => void;
  className?: string;
  tabIndex?: number;
  // when true, pass the selected address through onAddressSelect, even if the details callback will be called.
  // this is in case the details response takes a long time and the user clicks away.
  selectIntermediateAddressLine?: boolean;
  placeholder?: string;
  defaultValue?: string
}

export function BareAddressSelector(props: BareAddressSelectorProps) {
  const {
    gnafCentre,
    international,
    autoFocus,
    label,
    onAddressSelect,
    id,
    className,
    tabIndex,
    selectIntermediateAddressLine,
    placeholder,
    containerClassName,
    defaultValue
  } = props;

  const [suggestions, setSuggestions] = useState([] as Suggestion[]);
  const [isSearching, setIsSearching] = useState(0);
  const isOnline = useOnline();
  const activeSearch = useRef<{
    abort: () => void,
    response: Promise<IPropertySearchSuggestions | undefined>
  }>();

  const [valueOverride, setValueOverride] = useState<string|null>(defaultValue || null);

  const searchFunc = useCallback((query: string) => {
    activeSearch.current?.abort();
    if (!isOnline) return;
    if (!query || query.trim() === '') {
      return;
    }

    setIsSearching(s => s + 1);
    activeSearch.current = PropertySearchApi.getSuggestions(query, false, gnafCentre, international ?? false);
    activeSearch.current.response.then((suggestions) => {
      setSuggestions(suggestions?.Predictions.slice(0, 10).map(s => ({
        label: s.AddressParts ? composeFullAddressFromParts(s.AddressParts) : s.FullAddress,
        ...s
      })) ?? []);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setIsSearching(s => s - 1);
    });
  }, [international, gnafCentre, isOnline]);

  function handleSuggestionSelect([suggestion]: [IAddressSearchResponse]) {
    if (suggestion.AddressParts) {
      onAddressSelect(suggestion.FullAddress, suggestion.AddressParts, suggestion.GNAF || undefined);
      return;
    }

    if (suggestion.DetailCallback) {
      if (selectIntermediateAddressLine) {
        onAddressSelect(suggestion.FullAddress, undefined, suggestion.GNAF || undefined);
      }
      PropertySearchApi.getDetail(suggestion.DetailCallback.EndpointParams).response
        .then(({ Predictions: [addressResult] }) => {
          const parts = (addressResult as IAddressSearchResponse).AddressParts;
          let addressStr = suggestion.FullAddress;
          if (parts?.Postcode) {
            addressStr = stringifySaleAddress({ streetAddr_parts: parts });
            setValueOverride(addressStr);

          }
          onAddressSelect(addressStr, undefined, suggestion.GNAF || undefined);
        });
      return;
    }

    onAddressSelect(suggestion.FullAddress, undefined, suggestion.GNAF || undefined);
  }

  return <Typeahead
    debounceMs={0}
    label={label}
    isLoading={isSearching > 0}
    options={suggestions}
    autoFocus={autoFocus}
    onSearch={searchFunc}
    promptText='Type address to search'
    searchText='Searching for address'
    onSuggestSelect={handleSuggestionSelect}
    onChange={(e) => { onAddressSelect(e.target.value); }}
    id={id ?? 'address-selector'}
    className={className}
    valid={true}
    tabIndex={tabIndex}
    value={valueOverride}
    emptyLabel={isOnline ? null : `${OFFLINE_TEXT_SHORT}.`}
    placeholder={placeholder}
    containerClassName={containerClassName}
  />;
}
