import { useEffect, useState } from 'react';
import {
  ContentType, FormInstance,
  FormInstanceSigning,
  FormSigningState,
  SigningPartyType
} from '@property-folders/contract';
import { useLiveQuery } from 'dexie-react-hooks';
import { FileStorage, ReadFile, StorageItemFileStatus } from '@property-folders/common/offline/fileStorage';
import { AppFileProvider } from '@property-folders/components/dragged-components/signing/SigningProcess';
import * as Y from 'yjs';
import {
  determinePreviewFiles,
  generateDocumentDropdownInfo,
  getCompletedFiles,
  PreviewFile,
  PreviewType,
  PropertyFormYjsDal
} from '@property-folders/common/yjs-schema/property/form';
import { Predicate } from '@property-folders/common/predicate';
import { sortBy } from 'lodash';
import { YFormUtil } from '@property-folders/common/util/yform';
import { FileTrackState, PropertyRootKey, StillUploadingStates } from '@property-folders/contract/yjs-schema/property';
import { fillPdf } from '@property-folders/common/signing/fill-pdf';
import {
  applyPdfChanges,
  ChangeFn,
  makeUploadPdfChangeFns,
  PdfInformationExtractor
} from '@property-folders/common/util/pdf';
import { ExtractedField } from '@property-folders/common/util/pdf/types';
import { useFileTrack } from '../../hooks/useTransactionField';

function wrappedCreateObjectUrl(blob: Blob, name?: string) {
  const url = URL.createObjectURL(blob);
  console.log(`createObjectURL (${name || ''})`, blob.type, url);
  return url;
}

export interface PdfPreviewUrl {
  id: string,
  name: string,
  url: string
}

export type UnsetFieldsHandler = (fields: ExtractedField[]) => void;

/**
 * Prepare a pdf preview url for signing session preview.
 * note: was written for subscription forms, but could be extended out for regular stuff
 */
export function usePdfPreviewUrl({
  form,
  yDoc,
  formId,
  formCode,
  unsetFieldsHandler
}: {
  form: FormInstance | undefined,
  yDoc: Y.Doc | undefined,
  formId: string,
  formCode: string,
  unsetFieldsHandler?: UnsetFieldsHandler
}) {
  const signing = form?.signing;
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState<PdfPreviewUrl[]>([]);
  const state = signing?.state || FormSigningState.None;
  // if it's not nothing, and it's not configuring, then it must be a signing session - and we want to show for those.
  const show = (state !== FormSigningState.None && state !== FormSigningState.Configuring) || !!form?.order?.pdfPreview || !!form?.upload;
  const completedFiles = getCompletedFiles(signing?.session);
  const showFinal = completedFiles.length;
  const { files: previewFileList, fill: buildWithSignatures, buster: buildWithSignaturesBuster, applyActions } = determinePreviewFiles(form);
  const fileTrack = useFileTrack()??{};
  const previewFiles = useLiveQuery(async () => {
    const files = await Promise.all(previewFileList.map(pf => FileStorage.read(pf.id)));
    return files.filter<ReadFile>(Predicate.isNotNull);
  }, [signing?.session?.file?.id, previewFileList?.map(p=>p.id).join(''), signing?.session?.fields]) || [];

  const signatureFileList = form?.signing?.session?.fields?.map(field => field.file?.id)?.filter(Predicate.isTruthy)??[];
  const signatureFilesMeta = useLiveQuery(async () => {
    return signatureFileList ? Promise.all(signatureFileList?.map(fid => FileStorage.readMeta(fid))) : undefined;
  }, [signatureFileList?.join('')]);

  const filesReady = previewFiles.length === 0 || previewFiles?.every(p=>!p || p?.fileStatus===StorageItemFileStatus.Available);
  const sigsReady = signatureFilesMeta?.every(p=>p?.fileStatus===StorageItemFileStatus.Available);
  const pdfLoadErrorMessage = previewFileList.some(f=>f.id && StillUploadingStates.has(fileTrack[f.id]?.state))
    ? 'File is still being uploaded by other user'
    : previewFileList.some(f=>f.id && fileTrack[f.id]?.state === FileTrackState.ServerProcessing)
      ? 'Server is processing the file'
      : !filesReady
        ? 'Getting file'
        : !sigsReady
          ? 'Getting signatures'
          : '';

  useEffect(() => {
    if (!yDoc) {
      setPdfPreviewUrl([]);
      return;
    }
    if (!previewFiles.length) {
      setPdfPreviewUrl([]);
      return;
    }

    if (unsetFieldsHandler) unsetFieldsHandler([]);

    regeneratePreview({
      yDoc,
      formId,
      formCode,
      signing,
      buildWithSignatures,
      previewFileList,
      show,
      previewFiles,
      unsetFieldsHandler,
      actions: applyActions
        ? makeUploadPdfChangeFns(form?.upload?.actions || [], true)
        : undefined
    }).then(result => {
      if (!result.length) return;
      setPdfPreviewUrl(result);
    });
  }, [
    show,
    showFinal,
    JSON.stringify(previewFiles),
    !!yDoc,
    signing?.session?.id || '',
    signing?.session?.initiator?.timeZone || 'Australia/Adelaide',
    buildWithSignaturesBuster,
    applyActions
  ]);

  return { pdfPreviewUrl, pdfLoadErrorMessage };
}

async function regeneratePreview({
  yDoc,
  show,
  previewFiles,
  buildWithSignatures,
  previewFileList,
  signing,
  formCode,
  formId,
  unsetFieldsHandler,
  actions
}: {
  yDoc: Y.Doc,
  show: boolean,
  previewFiles: ReadFile[],
  buildWithSignatures: boolean,
  previewFileList: PreviewFile[],
  signing?: FormInstanceSigning,
  formCode: string,
  formId: string,
  unsetFieldsHandler?: UnsetFieldsHandler,
  actions?: ChangeFn[]
}): Promise<PdfPreviewUrl[]> {
  if (!(show && previewFiles[0]?.data && !!yDoc)) {
    return [];
  }
  if (!previewFiles?.every(p => p?.id)) {
    return [];
  }

  const parties = signing?.parties || [];
  const sortedPdfs = sortBy(previewFiles, pf =>
    parties
      .filter(p => p.signedPdf?.id === pf.id)
      .some(p => p.type === SigningPartyType.SignWet)
  );

  const previewPdfs: Array<PdfPreviewUrl & {signingPartyType?: SigningPartyType, previewType?: PreviewType}> = [];
  for (const [index, pf] of sortedPdfs.entries()) {
    const { text, signingPartyType } =  generateDocumentDropdownInfo(
      index + 1,
      previewFiles.length,
      pf.id,
      parties);
    const preview = previewFileList.find(p => p.id === pf?.id);
    const data = pf?.data
      ? actions?.length
        ? new Blob([
          await applyPdfChanges(await pf.data.arrayBuffer(), actions)
        ], { type: ContentType.Pdf })
        : pf.data
      : undefined;
    previewPdfs.push({
      id: pf?.id || '',
      name: text,
      url: data ? wrappedCreateObjectUrl(data, 'base/complete pdf') : '',
      signingPartyType,
      previewType: preview?.type
    });
  }

  const { dataRootKey, metaRootKey } = YFormUtil.getFormLocationFromId(formId, yDoc)??{ dataRootKey: PropertyRootKey.Data, metaRootKey: PropertyRootKey.Meta };

  // fill in pre-completion signing data
  if (buildWithSignatures && signing?.session?.id) {
    const bytes = await fillPdf(
      new PropertyFormYjsDal(yDoc, dataRootKey, metaRootKey),
      new AppFileProvider(),
      formId,
      formCode,
      signing.session.id,
      signing.session.initiator?.timeZone || 'Australia/Adelaide',
      true,
      false,
      {
        noFlatten: !!unsetFieldsHandler
      }
    );

    if (bytes) {
      if (unsetFieldsHandler) {
        try {
          const fp = await (new PdfInformationExtractor(bytes)).getFieldPositions();
          unsetFieldsHandler(fp);
        } catch (err: unknown) {
          console.error('failed to extract unfilled field info from pdf', err);
        }
      }
      const intermediate = previewPdfs.find(p => p.previewType === PreviewType.intermediate);
      intermediate && (intermediate.url = URL.createObjectURL(new Blob([bytes], { type: ContentType.Pdf })));
      if (intermediate) {
        intermediate.url = wrappedCreateObjectUrl(new Blob([bytes], { type: ContentType.Pdf }), 'filled pdf');
      }
    }
  }

  return previewPdfs;
}
