import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useEffectOnce, useInterval } from 'react-use';
import { getDefaultGcOpts, YManager, YManagerUser } from '@property-folders/common/offline/yManager';
import { Properties } from '@property-folders/common/client-api/properties';
import { YDocContentType } from '@property-folders/contract';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { useQueryClient } from '@tanstack/react-query';
import { AuthApi } from '@property-folders/common/client-api/auth';
import * as Y from 'yjs';
import { useOnline } from '../hooks/useOnline';

interface IYManagerContextData {
  instance?: YManager;
  loaded?: boolean;
}

export const YManagerContext = createContext<IYManagerContextData>({});

async function handleInterval(instance: YManager | undefined, maxProperties: number) {
  if (!instance) {
    return;
  }
  // fetch and apply last accessed property changes
  const recent = await Properties.getRecent() || { items: [] };
  // largest first, only get at most maxProperties of them, the rest would be gc'd straight away
  const toFetch = recent.items.sort((a, b) => b.timestamp - a.timestamp).slice(0, maxProperties);
  for (const item of toFetch) {
    instance.get(item.id, YDocContentType.Property, {});
  }

  await instance.garbageCollect();
}

export function SetupYManagerContext(
  props: {
    user: YManagerUser,
    offlineEnabled: boolean,
    fileSync?: FileSync,
    children: ReactNode,
    enabled: boolean,
    websocketEndpoint?: string,
    onYManagerDisconnect?: ()=>void
    impersonator: boolean
  }
) {
  const isOnline = useOnline();
  const queryClient = useQueryClient();
  const value: IYManagerContextData = {
    instance: props.enabled
      ? YManager.instance({
        ...props,
        onDisconnect: () => {props.onYManagerDisconnect?.(); AuthApi.potentiallyInvalidateSessionInfoQueries(queryClient);}
      })
      : undefined,
    loaded: true
  };
  const gcInterval = value.instance
    ? value.instance.gcInterval
    : getDefaultGcOpts().gcInterval;
  const maxProperties = value.instance
    ? value.instance.maxProperties
    : getDefaultGcOpts().maxProperties;
  useEffectOnce(() => {
    if (!('agentId' in props.user)) return;
    if (!isOnline) return;
    handleInterval(value.instance, maxProperties).catch(console.error);
  });
  useInterval(() => {
    if (!('agentId' in props.user)) return;
    if (!isOnline) return;
    handleInterval(value.instance, maxProperties).catch(console.error);
  }, gcInterval / 2);
  useEffect(() => {
    if (value.instance) {
      return () => {
        YManager.unbind();
      };
    }
  }, [value.instance]);

  return (
    <YManagerContext.Provider value={value}>
      {props.children}
    </YManagerContext.Provider>
  );
}

export function WaitForLoadedYDoc({
  yDocId,
  yDocType,
  children,
  loadingElement,
  forceLoad
}: {
  yDocId: string,
  yDocType: YDocContentType,
  children: (yDoc: Y.Doc) => React.ReactNode,
  loadingElement?: React.ReactNode,
  forceLoad?: boolean
}) {
  const { loaded, instance } = useContext(YManagerContext);
  const [yDoc, setYDoc] = useState<Y.Doc | undefined>(undefined);

  useEffect(() => {
    if (!loaded) return;
    if (!instance) return;

    const abort = new AbortController();
    (async () => {
      const { doc, localProvider } = instance.get(yDocId, yDocType, { forceLoad });
      await instance.waitForSync(doc);
      if (abort.signal.aborted) return;
      await localProvider.whenSynced;
      if (abort.signal.aborted) return;
      setYDoc(doc);
    })();

    return () => {
      abort.abort();
    };
  }, [loaded, instance, forceLoad, yDocId]);

  if (!(loaded && instance && yDoc)) {
    if (loadingElement) {
      return <>{loadingElement}</>;
    }
    return <></>;
  }

  return <>{children(yDoc)}</>;
}
